import React from 'react'
import styled from 'styled-components'

const AppAuthorBioVijayWpper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 0px;
  margin-bottom: 50px;
  text-align: center; /* Center the heading and slider */

  .AuthorBioVijayContain {
    max-width: 95vw;
    margin: auto;
    background-color: #102c64;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 42px;
  }

  .sliderContainer {
    width: 80vw; /* Adjusted width to fit content */
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }

  .sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    display: flex; /* Use Flexbox to align content side by side */
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    min-width: 100%; /* Full width for each slide */
    box-sizing: border-box;
    color: #fff;
    text-align: left; /* Align text to the left */
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 1rem; /* Add some padding to prevent content from touching the edges */
    }
  }

  .slideContent {
    flex: 1;
    padding-left: 2rem; /* Add space between image and text */
    @media (max-width: 768px) {
      padding-left: 0; /* Remove left padding on smaller screens */
      text-align: center; /* Center text on smaller screens */
    }
  }

  .AuthorBioVijay-sliderImage {
    width: 25vw; /* Fixed width for the image */
    height: 25vw; /* Set height equal to width to make it a perfect circle */
    border: 4px solid #34a1d5;
    border-radius: 50%; /* Use 50% to make it a perfect circle */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    @media (max-width: 768px) {
      width: 50vw; /* Adjust image width for smaller screens */
      height: 50vw; /* Adjust height to match width for smaller screens */
      margin-bottom: 1rem; /* Add some space below the image */
    }
    @media (min-width: 1800px) {
      width: 21vw;
      height: 21vw; /* Adjust height to match width for larger screens */
    }
  }

  .AuthorBioVijay-sliderAnchor {
    width: auto; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
  }

  .AuthorBioVijayHeading {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bolder;
    @media (max-width: 768px) {
      padding-top: 12px;
    }
    @media (max-width: 430px) {
      font-size: 1.5rem;
    }
  }

  .AuthorBioVijayHeadingTwo {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bolder;
    padding-top: 12px;
    @media (max-width: 768px) {
      padding-top: 12px;
    }
    @media (max-width: 425px) {
      padding-top: 2px;
      font-size: 1rem;
    }
  }

  .AuthorBioVijaySubheading {
    color: #d4d4d4;
    margin-bottom: 0.5rem;
    @media (max-width: 425px) {
      font-size: 0.9rem;
    }
  }

  .AuthorBioVijayPara {
    padding-top: 32px;
    color: #fff;
    margin-bottom: 1rem;
    line-height: 28px;
    @media (max-width: 768px) {
      padding-top: 16px; /* Adjust padding for smaller screens */
    }
    @media (max-width: 425px) {
      width: 80vw;
      font-size: 14px;
      line-height: 24px;
    }
  }
`

export const AppAuthorBioVijay = () => {
  const slide = {
    heading: 'Vijay Caveripakkam',
    headingTwo: 'Raindrop',
    subheading: 'Founder & CEO at Raindrop',
    para: '“Vijay is a high-energy, visionary technology leader with deep background expertise in software, sourcing, program management, IT operations, business transformations, mergers and acquisitions. He has great experience in building and scaling global companies.”',
    imageSrc: 'https://storage.googleapis.com/raindroppublic/website_data/RaindropTeamVijayPic.jpg',
    link: 'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAABLMr8B_uPO6ZhMWzVcpEu_lqYQ668cYis&keywords=vijay%20caveripakkam&origin=RICH_QUERY_SUGGESTION&position=1&searchId=a5af9cac-bddb-45e7-a525-22231bdec8c9&sid=pvi&spellCorrectionEnabled=false',
  }

  return (
    <AppAuthorBioVijayWpper>
      <div className="AuthorBioVijayContain">
        <div className="sliderContainer">
          <div className="sliderWrapper">
            <div className="slide">
              <a href={slide.link} target="_blank" rel="noopener noreferrer" className="AuthorBioVijay-sliderAnchor">
                <img src={slide.imageSrc} alt="Vijay Caveripakkam" className="AuthorBioVijay-sliderImage" />
              </a>
              <div className="slideContent">
                <div className="AuthorBioVijayHeading">{slide.heading}</div>
                <div className="AuthorBioVijayHeadingTwo">{slide.headingTwo}</div>
                <div className="AuthorBioVijaySubheading">{slide.subheading}</div>
                <div className="AuthorBioVijayPara">{slide.para}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppAuthorBioVijayWpper>
  )
}
