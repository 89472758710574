import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOneLeft: "Here's what caught our eye: By 2026, over",
    ParaOneAnchor: '80% of enterprises',
    ParaOneRight:
      "will have deployed AI-enabled applications in production environments, up from less than 5% in 2023. AI isn’t just a trend—it's a fundamental shift in how business gets done. But amid this gold rush mentality, a pivotal question remains unanswered:",
    ShiftUrlParaOne:
      'https://www.gartner.com/en/newsroom/press-releases/2023-10-11-gartner-says-more-than-80-percent-of-enterprises-will-have-used-generative-ai-apis-or-deployed-generative-ai-enabled-applications-by-2026',
    ParaTwo: "Which investments will actually strike gold, and which are just fool's gold?",
    ParaThree:
      "If you're feeling skeptical, good. That's exactly where this conversation needs to start. Because while everyone's talking about AI reinventing Procurement, what really matters is whether it can help your team process invoices faster, negotiate better deals, and make smarter decisions with less effort.",
    ParaFour: "Let's look at what works, what doesn't, and how to tell the difference.",
    HeadingOne: "Let's Cut Through the AI Noise",
    ParaFive:
      "Remember the blockchain hype of 2021? Everyone promised it would revolutionize Procurement, yet most of us still await that “revolution.” AI is different though—it's already changing how Procurement teams work.",
    ParaSix: "But only when it's done right.",
    ParaSeven:
      "Before we look at what works, let's examine what's really happening in organizations trying to adopt AI.",
    HeadingTwo: "The Numbers Don't Lie (But They Might Mislead)",
    ParaEightLeft: "Here's an interesting disconnect",
    ParaEightAnchor: '92% of Chief Procurement Officers',
    ParaEightRight: `say they're "planning or assessing" AI capabilities. But only 37% have actually started using it.`,
    ShiftUrlParaEight: 'https://procurementmag.com/technology-and-ai/what-impact-will-ai-have-on-procurement-in-2025',
    ImagePathOne: 'https://storage.googleapis.com/raindroppublic/website_data/Your-Guide-to-AI.jpg',
    ParaNine: 'Why such a big gap?',
    ParaTen: `Because there's a world of difference between adding AI to your tech wishlist and implementing solutions that solve real problems. Most teams get stuck asking, "Where do we even start?"`,
    HeadingThree: `Here's what's happening in Procurement right now:`,
    ParaEleven: '◉ Teams rushing to buy AI solutions before identifying what they need to fix',
    ParaTwelve: `◉ Vendors rebranding basic automation as "AI-powered"`,
    ParaThirteen: `◉ Procurement Leaders stuck between pressure to innovate and fear of making expensive mistakes`,
    HeadingFour: 'When organizations sprint toward AI without a game plan, they usually end up with:',
    ParaFourteen: `◉ Data headaches ("Why isn't our AI working…Because our data's a mess.")`,
    ParaFifteen: `◉ Integration nightmares ("It works great! Except with all our other systems.")`,
    ParaSixteen: `◉ Frustrated teams ("Another tool that creates more work than it saves.")`,
    ParaSeventeen: `◉ Budget drains ("We spent how much to automate what exactly?")`,
    HeadingFive: `What's Actually Working?`,
    ParaEighteenLeft: `The organizations getting real value from AI aren't following the hype. According to`,
    ParaEighteenAnchor: `recent research,`,
    ParaEighteenRight: `they're seeing concrete results:`,
    ShiftUrlParaEighteen:
      'https://procurementmag.com/technology-and-ai/what-impact-will-ai-have-on-procurement-in-2025',
    ParaNineteen: '◉ 50% report doubling their ROI compared to traditional methods',
    ParaTwenty: '◉  Up to 100% improvement in compliance processes',
    ParaTwentyOne: '◉  40% increase in transaction speeds',
    ParaTwentyTwo: '◉  Up to 40% reduction in operational costs',
    ParaTwentyThree: `The difference? They started with the problem, not the technology. And they’re building on proven platforms (like Google Cloud) rather than betting on unproven tech.`,
    ParaTwentyFour: `So, start with the problems that keep you up at night, then find AI solutions that actually solve them.`,
    HeadingSix: 'Four Ways Raindrop AI Delivers Value',
    ParaTwentyFive: `Time to get specific. Raindrop's AI platform tackles Procurement's biggest challenges head-on. Here's how we're making Procurement Teams' lives easier and their results better.`,
    HeadingSeven: '1. Sourcing That Does the Heavy Lifting',
    ParaTwentySix: `Ever missed a crucial detail in supplier email #247? Or watched the numbers swim before your eyes during bid analysis? Thankfully, AI solves these everyday procurement headaches.`,
    ParaTwentySevenLeft: 'Raindrop is built on the',
    ParaTwentySevenAnchor: 'Google Cloud Platform',
    ParaTwentySevenRight: `and turns weeks of tedious work into something manageable. Sourcing events now take minutes. Hundreds of supplier bids get analyzed while you grab a coffee. Money-saving opportunities pop up before you look for them. Finally, you can think about strategy instead of wrestling with spreadsheets.`,
    ShiftUrlParaTwentySeven:
      'https://console.cloud.google.com/marketplace/product/raindrop-public/www.raindrop.com?hl=it&invt=AbrY-w',
    ParaTwentyEight: `Raindrop's autonomous sourcing capabilities also make negotiations effortless. Our system doesn't just watch bids—it actively drives value. It automatically nudges suppliers toward competitive pricing without buyer intervention, giving suppliers instant feedback on their position. This means faster savings across more spend categories, without adding headcount.`,
    ParaTwentyNine: `Plus, Raindrop's AI becomes your strategic partner for complex sourcing events. It analyzes massive multi-supplier negotiations in real time, highlighting who's ahead, who's behind, and exactly where you need to focus. The result? Faster time to value, broader spend coverage, and consistently better outcomes.`,
    HeadingEight: '2. Contract Management That Makes Legal Happy',
    ParaThirtyOne: `Your legal team didn't go to law school to spend hours reviewing standard agreements. Yet here they are, spending their time on routine document changes.`,
    ParaThirtyTwo: `Raindrop's GenAI puts an end to that. It pulls from your existing agreements, clause library, and requirements to create new contracts in minutes. Your legal team gets to be, well, legal experts again.`,
    ParaThirtyThree: `While your team sleeps, Raindrop keeps watch. It spots risky clauses, flags upcoming deadlines, and catches compliance issues before they become problems.`,
    ParaThirtyFour: `Every contract in your system makes the AI smarter. It picks up your preferences, adjusts to new regulations, and applies your best practices automatically. Past experience shapes future decisions.`,
    ParaThirtyFive: `The payoff? Your legal team actually practices law. Contracts flow faster. Risks surface sooner. And those high-value resources finally deliver the value you're paying for.`,
    HeadingNine: '3. AI Assistants That Actually Help',
    ParaThirtySix: `Most chatbots barely understand basic questions. But Raindrop serves as your Digital Front Door to Procurement—opening up access to information that used to feel trapped in various systems.`,
    ParaThirtySeven: `Need data that's buried in your systems? Just ask Raindrop like you'd ask a colleague: "Show me invoices on hold" or "Pull up my team's POs." The days of building complex queries are over. Information flows directly to the people who need it.`,
    ParaThirtyEight: `Raindrop guides you through Procurement processes naturally. It suggests next steps, points you to approved vendors, and explains policies in plain language. Following business rules becomes the easy path, not an obstacle course.`,
    ParaThirtyNine: `Your team gets a Procurement expert that's always available. One that remembers every policy, tracks every workflow, and ensures nothing slips through the cracks. No more hunting through shared drives or pestering colleagues for answers.`,
    ParaThirtyTen: `The outcome? Information flows freely. Processes run smoothly. Your team spends time on what matters instead of searching for answers.`,
    HeadingTen: '4. Processing That Actually Works',
    ParaThirtyEleven: `Every supplier has their own way of sending information. PDFs, spreadsheets, emails, images—Raindrop handles them all. Our multimodal AI processes every type of data, moving way beyond basic OCR and template matching.`,
    ParaThirtyTwelve: `This means your suppliers can send invoices however they want. Raindrop's AI looks at everything—document layout, email content, attachment data, even logos—to understand and map information correctly. No more forcing suppliers into rigid templates or manual data entry. Whatever lands in your inbox gets processed, routed, and handled automatically.`,
    ParaThirtyThirteen: `Think about where your data lives right now. Scattered across systems, buried in emails, hidden in spreadsheets. Raindrop’s AI recognizes that "Acme Corp" in an email and "ACME LLC" in a PDF are the same company. Plus, it standardizes information from any source. And gets smarter with every document.`,
    ParaThirtyFourteen: `While other solutions are still figuring out basic automation, Raindrop's already using next-generation AI agents to handle your complex data needs. We're not just solving today's processing challenges—we're ready for tomorrow's.`,
    HeadingEleven: 'How to Pick the Right AI Partner',
    ParaThirtyFifteen: `By now you're thinking, "Okay, AI works. But how do I choose wisely?" Here's how to separate the real solutions from the pretenders.`,
    HeadingTwelve: 'Warning Signs: When to Walk Away',
    ParaFourtyOne: `As you evaluate AI solutions for your Procurement Team, watch out for vendors that say:`,
    ParaFourtyTwo: `◉ "Let me explain our AI capabilities." (cue buzzword bingo)`,
    ParaFourtyThree: `◉ "Clean up your data, then we'll talk."`,
    ParaFourtyFour: `◉ "Oh, we don't integrate with that system."`,
    ParaFourtyFive: `◉ "Our AI solves everything, magically!"`,
    ParaFourtySix: `◉ "Security strategy? We're working on that."`,
    ParaFourtySeven: `Here’s what to look for instead:`,
    ImagePathTwo: 'https://storage.googleapis.com/raindroppublic/website_data/AI-Evaluation-Criteria-cards.jpg',
    HeadingThirteen: 'Build a Smarter Procurement Future with Raindrop',
    ParaFiftyOne: `Here's the thing about AI in Procurement: It's not about jumping on the latest tech bandwagon. It's about making your team's life easier and your results better. We already see this, with 72% of CPOs prioritizing AI because it works.`,
    ParaFiftyTwo: `But success depends on choosing the right partner. So, you need one who:`,
    ParaFiftyThree: `◉ Gets Procurement (not just AI)`,
    ParaFiftyFour: `◉ Solves real problems (not theoretical ones)`,
    ParaFiftyFive: `◉ Speaks human (not buzzword)`,
    ParaFiftySix: `While some vendors push point solutions that solve single problems, Procurement Transformation requires a more comprehensive approach. You need a partner with an integrated platform that addresses your entire Procurement lifecycle—from initial supplier intake and sourcing, to contracts and compliance, to ordering and invoice automation and payments.`,
    ParaFiftySeven: `Because while AI is changing Procurement, one thing hasn't changed: Success comes from choosing partners who understand your complete Procurement journey.`,
  },
]
