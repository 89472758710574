import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import YourGuidetoAIinProcurementRestData from '../Data/YourGuidetoAIinProcurementRestData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppYourGuidetoAIinProcurementRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .GuidetoAIContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .GuidetoAIHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .GuidetoAILeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .GuidetoAILeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .GuidetoAIParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 24px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .GuidetoAIHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }
`

export const AppYourGuidetoAIinProcurementRest = () => {
  return (
    <AppYourGuidetoAIinProcurementRestWapper>
      <div>
        {YourGuidetoAIinProcurementRestData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="GuidetoAIContentSectionEmail">
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaOneLeft}
                  anchorPara={data.ParaOneAnchor}
                  ParaRight={data.ParaOneRight}
                  href={data.ShiftUrlParaOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUsePtag para={data.ParaTwo} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaThree} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaFour} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="GuidetoAIHeadingEmail" />
                <ReUsePtag para={data.ParaFive} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaSix} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaSeven} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="GuidetoAIHeadingEmail" />
                <img src={data.ImagePathOne} className="BlogsimageOne" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaEightLeft}
                  anchorPara={data.ParaEightAnchor}
                  ParaRight={data.ParaEightRight}
                  href={data.ShiftUrlParaEight}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUsePtag para={data.ParaNine} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaTen} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="GuidetoAIHeadingEmail" />
                <ReUsePtag para={data.ParaEleven} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaTwelve} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaThirteen} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaFourteen} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaFifteen} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaSixteen} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUsePtag para={data.ParaSeventeen} paraStyle="GuidetoAILeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaEighteenLeft}
                  anchorPara={data.ParaEighteenAnchor}
                  ParaRight={data.ParaEighteenRight}
                  href={data.ShiftUrlParaEighteen}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUsePtag para={data.ParaNineteen} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwenty} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyThree} paraStyle="GuidetoAILeadParaStyle" />
                <ReUsePtag para={data.ParaTwentyFour} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwentyFive} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSeven} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwentySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaTwentySevenLeft}
                  anchorPara={data.ParaTwentySevenAnchor}
                  ParaRight={data.ParaTwentySevenRight}
                  href={data.ShiftUrlParaTwentySeven}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="ParaStyleMultiple"
                />
                <ReUsePtag para={data.ParaTwentyEight} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyNine} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingEight} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyFive} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingNine} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtySeven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyEight} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyNine} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingTen} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtyEleven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTwelve} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyThirteen} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyFourteen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingEleven} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtyFifteen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingTwelve} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaFourtyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourtyTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourtyThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourtyFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourtyFive} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourtySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourtySeven} paraStyle="ParaStyleMultiple" />
                <img src={data.ImagePathTwo} className="BlogsimageOne" />
                <ReUseHTwotag Heading={data.HeadingThirteen} HeadingStyle="GuidetoAIHeadingFiveEmail" />
                <ReUsePtag para={data.ParaFiftyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFiftyTwo} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFiftyThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFiftyFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFiftyFive} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFiftySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFiftySeven} paraStyle="ParaStyleMultiple" />
              </div>
            </div>
          )
        })}
      </div>
    </AppYourGuidetoAIinProcurementRestWapper>
  )
}
